<template>
  <div id="app">
    <div class="container">
      <div class="content">
        <el-card>
          <p style="color:coral">今日可花：<span style="font-size: 20px;">{{canPay}}</span></p>
          <form @submit.prevent.stop="handleInput">
            <el-input autofocus native-type="number" placeholder="请输入支出" v-model="payInput" class="input-with-select">
              <el-button type="primary" slot="append" icon="el-icon-edit" @click="handleInput">记一笔</el-button>
            </el-input>
          </form>
          <p  style="color:forestgreen">结余：<span style="font-size: 20px;">{{balance}}</span></p>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import store from 'store'
import observePlugin from 'store/plugins/observe'
store.addPlugin(observePlugin)
import moment from 'moment'
import { getShouldPayToday, today, setStore, conrrectNumber } from './utils'
const todayCanCost = getShouldPayToday(moment())

const initData = () => {
  // 初始化
  if (!store.get('cost') || !store.get('costSummary')) {
    store.set('cost', {
      value: 0,
      date: today,
    })
    store.set('costSummary', {
      [today]: 0,
    })
    store.set('balance', todayCanCost)
    store.set('balanceRecord', {
      [today]: todayCanCost,
    })
  }

  // 判断当日消费过天就更新一下
  if ( moment.duration(moment().diff( moment(store.get('cost').date))).get('days') >= 1) {
    store.set('cost', {
      value: 0,
      date: today,
    })
    setStore('costSummary', {
      [today]: 0,
    })
    // 余额发放
    const balance = conrrectNumber(store.get('balance') + todayCanCost)
    store.set('balance', balance)
    setStore('balanceRecord', {
      [today]: balance,
    })
  }
}

initData()
export default {
  name: 'app',
  data() { 
    return {
      payInput: '',
      // 今日可花 = 当日发放-当日消费
      canPay: conrrectNumber(todayCanCost - store.get('cost').value),
      balance: store.get('balance'),
    }
  },
  mounted() {
    // // 初始化判断余额不存在发放余额
    // const balanceStore = store.get('balance')
    // if (!balanceStore) {
    //   store.set('balance', {
    //     grant_at: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    //     value: getShouldPayToday(moment())
    //   })
    // } else {
    //   const diffGrantDay = moment.duration(moment().diff( moment(balanceStore.grant_at))).get('days')
    //   if (diffGrantDay > 0) {
    //     let newBalanceStore = store.get('balance')
    //     for (let i = 1; i <= diffGrantDay; i++) {
    //       const grantDay =  moment(newBalanceStore.grant_at).add(1, 'days')
    //       const grant_at =  grantDay.startOf('day').format('YYYY-MM-DD HH:mm:ss')
    //       const value = newBalanceStore.value + getShouldPayToday(grantDay)
    //       newBalanceStore = {
    //         grant_at,
    //         value
    //       }
    //     }
    //     store.set('balance', newBalanceStore)
    //   }
    // }
    // this.balance = store.get('balance').value

  },
  methods: {
    handleInput() {
      const pay = Number(this.payInput)
      // 清除缓存
      if (this.payInput === 'clear') {
        this.payInput = ''
        store.clearAll()
        initData()
        this.canPay = conrrectNumber(todayCanCost - store.get('cost').value)
        this.balance = store.get('balance')
        this.$message({
          message: '清除缓存成功',
          type: 'success'
        });
        return
      }
      if (isNaN(pay)) {
        this.$message({
          message: '请填写数字',
          type: 'warning'
        });
        this.payInput = ''
        return
      }
      
      this.canPay = conrrectNumber(this.canPay - pay)
      // 增加当日消费
      const cost = conrrectNumber(store.get('cost')['value'] + pay)
      // 增加每日消费统计
      const costSummary = store.get('costSummary')[today] = pay
      // 余额减去当日消费
      const balance = conrrectNumber(store.get('balance') - pay)

      // 设置store
      setStore('cost', {
        value: cost
      })
      setStore('costSummary', {
        [today]: costSummary
      })
      store.set('balance', balance)
      
      this.balance = balance
      this.payInput = ''
    }
  },
  watch: {
    balance() {
       // 更新每日余额记录
      setStore('balanceRecord', {
        [today]: store.get('balance'),
      })
    }
  }
}
</script>

<style lang="less">
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    width: 100%;
  }
}

</style>
