import moment from 'moment'
import store from "store";

export const today = moment().format('YYYY-MM-DD')

export const initCost = {
  value: 0,
  date: today,
};

export const initCostSummary = {
  [today]: 0,
};

export const initBalance = 0

export const initBalanceRecord = {
  [today]: 0,
}

export const getShouldPayToday = (momentDate) => {
  if ([1, 2, 3, 4, 5].includes(momentDate.day())) {
    return 50;
  } else {
    return 100;
  }
};

export const setStore = (name, data) => {
  const oldData = store.get(name)
  store.set(name, { ...oldData, ...data });
}

export const conrrectNumber = (num) => Number(num.toFixed(2))